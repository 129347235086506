import { ActionNames } from '../actions';

export enum ApplicationType {
  applicationUI = 'ApplicationUI',
  backgroundWorker = 'BackgroundWorker',
}

export enum EHRResource {
  patient = 'patient',
  encounter = 'encounter',
  referral = 'referral',
  orders = 'orders',
}

export type EHRResourceType = {
  [resource in EHRResource]?: boolean;
};

export type ActionsType = { [action in ActionNames]?: boolean };

type ReadOnlyPermissions = Pick<Permissions, 'read'>;
export type SessionPermissions = {
  user?: {
    identifiers?: ReadOnlyPermissions;
    demographics?: ReadOnlyPermissions;
    contactInfo?: ReadOnlyPermissions;
  };
  organization?: {
    identifiers?: ReadOnlyPermissions;
  };
  ehr?: {
    identifiers?: ReadOnlyPermissions;
  };
};

export interface SessionSection {
  permissions?: SessionPermissions;
}

export interface ApplicationManifest {
  metadata: MetadataSection;
  session?: SessionSection;
  UI: UISection;
  EHR: EHRSection;
  auth?: VimAuthSection;
  vimCapabilities: VimCapabilitiesSection;
  version: string;
}

export type AppId = ApplicationManifest['metadata']['id'];

export type AppStoreServiceManifest = {
  id: string;
  version?: string;
  metadata: {
    description?: string;
    url: string;
  };
  capabilities: {
    enrichEHR?: {
      allowedPII?: boolean;
      resources: EHRResourceType;
    };
    enrichUser?: boolean;
    custom?: boolean | null;
    actions?: ActionsType | null;
  };
};

export interface MetadataSection {
  id: string;
  name: string;
  url: string;
  type: ApplicationType;
  services?: AppStoreServiceManifest[];
  launchEndpoint?: string;
  tokenEndpoint?: string;
  allowedUrls?: string;
}

export type Permissions = {
  read?: boolean;
  write?: boolean;
};

export type ResourcePermissions = {
  [EHRResource.referral]?: {
    identifiers?: Permissions;
    basicInformation?: Permissions;
    conditions?: Permissions;
    procedureCodes?: Permissions;
    referringProvider?: Permissions;
    targetProvider?: Permissions;
  };
  [EHRResource.orders]?: {
    identifiers?: Permissions;
    basicInformation?: Permissions;
    assessments?: Permissions;
    procedureCodes?: Permissions;
  };
  [EHRResource.encounter]?: {
    identifiers?: Permissions;
    basicInformation?: Permissions;
    subjective?: Permissions;
    objective?: Permissions;
    assessment?: Permissions;
    plan?: Permissions;
    patientInstructions?: Permissions;
    provider?: Permissions;
  };
  [EHRResource.patient]?: {
    identifiers?: Permissions;
    demographics?: Permissions;
    address?: Permissions;
    contactInformation?: Permissions;
    insuranceInfo?: Permissions;
    problemList?: Permissions;
    pcp?: Permissions;
  };
};

export interface EHRSection {
  resources?: EHRResourceType | null;
  resourceUpdates?: EHRResourceType | null;
  resourcePermissions?: ResourcePermissions;
  allowedPII?: boolean;
  actions?: ActionsType;
  launchButtonRealEstate?: LaunchButtonRealEstateType;
}

export enum ReferralRealEstate {
  REFERRAL_SELECT_PROVIDER = 'REFERRAL_SELECT_PROVIDER',
}

export enum OrdersRealEstate {
  DI_SELECT_PROVIDER = 'DI_SELECT_PROVIDER',
  LABS_SELECT_PROVIDER = 'LABS_SELECT_PROVIDER',
}

export type LaunchButtonRealEstateType = {
  [EHRResource.referral]?: {
    [realEstate in ReferralRealEstate]?: boolean;
  };
  [EHRResource.orders]?: {
    [realEstate in OrdersRealEstate]?: boolean;
  };
  [EHRResource.encounter]?: never;
  [EHRResource.patient]?: never;
};

export interface VimCapabilitiesSection {
  trackAnalytics?: boolean;
}

export interface VimAuthSection {
  vimTokens: boolean;
}

export enum ApplicationSize {
  CLASSIC = 'CLASSIC',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
}

export type CapabilitiesKeys =
  | 'autoPopup'
  | 'popupNotification'
  | 'showPatientHeader'
  | 'counterNotificationLabel'
  | 'overridePopupLimit'
  | 'appAlwaysAvailable';

export type CapabilitiesFeatures = {
  [key in CapabilitiesKeys]?: boolean;
};

export interface UISection {
  icon: string;
  iconUrl?: string;
  tooltip: string;
  size?: ApplicationSize;
  capabilities: CapabilitiesFeatures;
  launchButton?:
    | {
        // Controls how the button will look like
        // if it's not active after the loading phase
        irrelevantVisibility: 'hidden' | 'disabled';
        alt: string;

        // button background color
        primaryColor: string;

        // button border color
        borderColor: string;

        // button text color
        fontColor: string;

        mode: {
          [mode in 'small' | 'medium' | 'large']: {
            text?: string;
            image?: string;
          };
        };
      }
    | {
        customHTML: string;
      };
}
